import { graphql } from "gatsby"
import Layout, { IMetadata } from "../components/Layout"
import React from "react"
import Blocks from "../components/blocks/Blocks";

export default ({ data }) => {
    const landingpage: any = data.landingpagesHJson

    const lpCrumbs = (landingpage.breadcrump) ?  landingpage.breadcrump : [];

    const crumbs = [
        { label: "Home", url: "" },
        { label: "Themen", url: "themen" },
        ...lpCrumbs,
        { label: landingpage.name, url: landingpage.url },
    ];

    // Create metadata object
    const metaData: IMetadata = {};
    if (landingpage.meta) { 
        if (landingpage.meta.description) {
                metaData.description = landingpage.meta.description;
            }
            if (landingpage.meta.title) {
                metaData.title = landingpage.meta.title;
            }
            if (landingpage.meta.ogImage) {
                metaData.ogImage = landingpage.meta.ogImage.childImageSharp.gatsbyImageData.images.fallback.src;
            }
            if (landingpage.meta.ogTitle) {
                metaData.ogTitle = landingpage.meta.ogTitle;
            }
            if (landingpage.meta.ogDescription) {
                metaData.ogDescription = landingpage.meta.ogDescription;
            }
        }


    return (
        <Layout crumbs={crumbs} metadatas={metaData} hasWave={false} headerColor="light">
            {/* <Hero
                title={landingpage.name}
                subtitle={landingpage.description}
            /> */}
           <Blocks blocks={landingpage.blocks} />
        </Layout>
    )
}

export const materialQuery = graphql`query LandingpageQuery($url: String!) {
  landingpagesHJson(url: {eq: $url}) {
    meta {
      title
      description
      ogTitle
      ogImage {
        childImageSharp {
          gatsbyImageData(width: 300, height: 300, layout: FIXED)
        }
      }
      ogDescription
    }
    name
    url
    blocks {
      ...BlocksFragment
    }
    description
    breadcrump {
      label
      url
    }
  }
}
`
